import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Card from "../components/card"

const AboutPage = () => {
  const data = useStaticQuery(graphql`
    query cardDataQuery {
      site {
        siteMetadata {
          bioList {
            name
            bio
          }
        }
      }
    }
  `)
  var items = []
  for (const [index, value] of data.site.siteMetadata.bioList.entries()) {
    items.push(
      <div key={index}>
        <Card name={value.name} bio={value.bio}></Card>
      </div>
    )
  }

  return (
    <Layout>
      <SEO title="About Us" />
      <div>
        <h1>Meet the Team</h1>
        {items}
      </div>
    </Layout>
  )
}

export default AboutPage
