import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "../components/image"
import Img from "gatsby-image"

class Card extends React.Component {
  render() {
    const { name, bio } = this.props

    return (
      <div style={styles.cardStyle}>
        <div style={styles.cardSubDiv}>
          <h3>{name}</h3>
          <p>{bio}</p>
        </div>
        <div style={styles.imageStyle}>
          <Image />
        </div>
      </div>
    )
  }
}

const styles = {
  cardStyle: {
    border: "2px solid DarkCyan",
    borderRadius: "4px",
    display: "flex",
    flexDirection: "row",
    margin: "10px",
    boxShadow: "4px 8px grey",
    padding: "10px",
  },
  cardSubDiv: {
    width: "50%",
    alignSelf: "center",
  },
  imageStyle: {
    width: "50%",
    alignSelf: "center",
  },
}

export default Card
